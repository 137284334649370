import React, { useContext, useEffect } from "react";
import { GlobalDispatchContext } from "../context/GlobalContextProvider";

import Layout from "../components/layout";
import SEO from "../components/seo";
import qrcode from "../images/qrcode-checkindonbosco.png";

function QRcode() {
  const dispatch = useContext(GlobalDispatchContext);

  useEffect(() => {
    dispatch({ type: "SET_NAMES", payload: [] });
    dispatch({ type: "SET_SUBMITTED", payload: false });
    dispatch({ type: "SET_SUCCESS", payload: false });
  }, []); //eslint-disable-line

  return (
    <Layout>
      <SEO keywords={[`qr code`]} title="DB COVID Screening" />

      <section className="flex flex-col items-center md:flex-row  px-6 mb-4">
        <div className="container mx-auto mt-8">
          <div className="max-h-screen">
            <img
              src={qrcode}
              className="mx-auto"
              style={{ maxHeight: "600px" }}
              alt="checkin.donbosco.ga"
            />
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default QRcode;
